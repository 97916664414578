import Link from "next/link";
import { useRouter } from "next/navigation";
import { CSSProperties, ReactNode, useContext } from "react";
import { UrlObject } from "url";
import { AuthorizationContext } from "./AuthorizationContext";
import { colors } from "./ThemeRegistry/theme";
export const CustomLink = ({
  href,
  children,
  sx
}: {
  href: any;
  children: ReactNode;
  sx?: CSSProperties;
}) => {
  const router = useRouter();
  const authenticatedContext = useContext(AuthorizationContext);
  const handleClick = (e: any) => {
    authenticatedContext.setCallbackRedirect(() => router.push(href));
    if (!authenticatedContext.unsavedChange) {
      router.push(href);
    }
    if (authenticatedContext.callbackUnSavedChange) {
      authenticatedContext.callbackUnSavedChange();
    }
    e.preventDefault();
  };
  return <Link href={href as unknown as UrlObject} onClick={handleClick} style={sx ?? {
    fontSize: "inherit",
    textDecoration: "none",
    textTransform: "none",
    lineHeight: "20px",
    color: colors.white
  }} data-sentry-element="Link" data-sentry-component="CustomLink" data-sentry-source-file="CustomLink.tsx">
            {children}
        </Link>;
};