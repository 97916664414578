import { Box, Typography, Divider } from "@mui/material";
import { colors, defaultStyles } from "@components/ThemeRegistry/theme";
import { FC, useContext } from "react";
import { AuthorizationContext } from "@components/AuthorizationContext";
import { IBreadCrumbLink } from "@definitions/index";
import { CustomLink } from "@components/CustomLink";
export const AppBreadcrumb: FC<{
  links: IBreadCrumbLink[];
  marginBottom?: string | number;
  useAppPadding?: boolean;
}> = ({
  links,
  marginBottom = "20px",
  useAppPadding = false
}) => {
  const authenticatedContext = useContext(AuthorizationContext);
  const linkStyle = {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "17px"
  };
  const linkStyleBold = {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "17px"
  };
  const redirectToHome = (e: any) => {
    authenticatedContext.setCallbackRedirect(() => {
      if (typeof window !== 'undefined' && location) {
        location.href = "/";
      }
    });
    if (!authenticatedContext.unsavedChange) {
      if (typeof window !== 'undefined' && location) {
        location.href = "/";
      }
    }
    if (authenticatedContext.callbackUnSavedChange) {
      authenticatedContext.callbackUnSavedChange();
    }
    e.preventDefault();
  };
  return <Box sx={{
    marginBottom: marginBottom,
    paddingLeft: useAppPadding ? defaultStyles.appPaddingLeft : 0,
    paddingRight: useAppPadding ? defaultStyles.appPaddingRight : 0,
    alignContent: "center"
  }} data-sentry-element="Box" data-sentry-component="AppBreadcrumb" data-sentry-source-file="AppBreadcrumb.tsx">
			<Box sx={{
      display: "inline-flex",
      minHeight: "35px"
    }} data-sentry-element="Box" data-sentry-source-file="AppBreadcrumb.tsx">
				<Box sx={{
        display: "flex",
        alignItems: "center"
      }} data-sentry-element="Box" data-sentry-source-file="AppBreadcrumb.tsx">
					<Typography sx={{
          fontWeight: 400,
          fontSize: "15px",
          lineHeight: "17px",
          cursor: "pointer"
        }} onClick={redirectToHome} data-sentry-element="Typography" data-sentry-source-file="AppBreadcrumb.tsx">
						Home
					</Typography>
				</Box>
				{links?.map((link, index) => {
        return link.href ? <Box key={index} sx={{
          display: "flex",
          alignItems: "center"
        }}>
								<CustomLink href={link.href} sx={{
            ...linkStyle,
            textDecoration: "none",
            textTransform: "none",
            color: colors.black
          }}>
									<Typography sx={index == links?.length - 1 ? linkStyleBold : linkStyle}>
										<span>&nbsp;</span> / {link.text}
									</Typography>
								</CustomLink>
							</Box> : <Box key={index} sx={{
          display: "flex",
          alignItems: "center"
        }}>
							<Typography sx={index == links?.length - 1 ? linkStyleBold : linkStyle}>
								<span>&nbsp;</span> / {link.text}
							</Typography>
						</Box>;
      })}
			</Box>

			<Divider sx={{
      borderColor: "#00000026",
      marginLeft: "-" + defaultStyles.appPaddingLeft,
      marginRight: "-" + defaultStyles.appPaddingRight
    }} data-sentry-element="Divider" data-sentry-source-file="AppBreadcrumb.tsx" />
		</Box>;
};